import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/vsts/work/1/s/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "how-to-resolve-problems-in-an-already-closed-report"
    }}>{`How to resolve problems in an already closed report`}</h1>
    <p>{`If a daily report has already been closed it can only be reopened by the Captain of the vessel.`}</p>
    <p>{`To reopen a report:`}</p>
    <ol>
      <li parentName="ol">{`Go to the report page`}</li>
      <li parentName="ol">{`Click on Reopen`}</li>
    </ol>
    <p>{`The report is then reset and needs to be approved again by the Bridge crew.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      